<template web>
  <div class="container" v-if="options && options.length">
    <h2 class="section-header max_header" v-if="current_option">
      <div class="btn-group" v-if="!is_capacitor">
        <button type="button" class="btn btn-light btn-lg dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false" style="height: auto; padding: 9px 10px 7px 10px;">
          {{ current_option.title || current_option.name }}
        </button>
        <div class="dropdown-menu" v-if="is_capacitor">
          <button @click="selectNativeOptions()">
          </button>
        </div>
        <div class="dropdown-menu" v-else>
          <v-select @click="selectOption(option)" v-for="option in options" :key="option.id"
            :menu-props="{ top: options.length <= 20, offsetY: options.length <= 20 }"
            class="dropdown-item available-option" type="button">
            {{ option.title || option.name }}
          </v-select>
        </div>
      </div>
      <div class="btn-group" v-else>
        <button type="button" class="btn btn-light btn-lg dropdown-toggle" @click="selectNativeOptions()" style="height: auto; padding: 9px 10px 7px 10px;
">
          {{ current_option.title || current_option.name }}
        </button>
      </div>

    </h2>
  </div>
</template>
<style>
.swal2-radio {
  display: grid !important;
  text-align: left !important;
}

.show {
  display: inline;
  transform: initial;
  margin-top: 3em;
}
</style>
<script>
import { Capacitor } from '@capacitor/core';
import { VSelect } from 'vue';

export default {
  name: 'dropdown-selector',
  props: ['options', 'default'],
  components: {
    VSelect,
  },
  data() {
    return {
      current_option: {},
    };
  },
  computed: {
    is_capacitor() {
      if ((Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android')) {
        return true;
      }
      return false;
    },
  },
  watch:
  {
    default:
    {
      immediate: true,
      handler(option) {
        this.current_option = option;
      },
    },
  },
  methods:
  {
    async selectNativeOptions() {
      const arrayOptions = [];
      let haveKeyName = false;
      let haveKeyTitle = false;
      await this.options.forEach(option => {
        // eslint-disable-next-line no-prototype-builtins
        if (option.hasOwnProperty('name')) {
          haveKeyName = true;
          arrayOptions[option.name] = option.name;
        } else {
          haveKeyTitle = true;
          arrayOptions[option.title] = option.title;
        }
      });

      this.$swal.fire({
        title: 'Seleccione una opción',
        input: 'radio',
        inputOptions: arrayOptions,
        inputPlaceholder: 'required',
        showCancelButton: true,
        inputValidator(value) {
          return new Promise((resolve, reject) => {
            if (value !== '') {
              resolve();
            } else {
              resolve('Necesita elegir una categoría');
            }
          });
        },
      }).then(async (result) => {
        if (haveKeyName) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0, l = this.options.length; i < l; i++) {
            if (this.options[i].name === result.value) {
              this.current_option = this.options[i];
              this.$emit('selected', this.current_option);
            }
          }
        } else {
          // eslint-disable-next-line no-plusplus
          for (let i = 0, l = this.options.length; i < l; i++) {
            if (this.options[i].title === result.value) {
              this.current_option = this.options[i];
              this.$emit('selected', this.current_option);
            }
          }
        }
      });
    },
    selectOption(option) {
      this.current_option = option;
      console.log(`current ${this.current_option}`);
      this.$emit('selected', this.current_option);
    },
  },
};
</script>
<style>
.show {
  top: auto;
  transform: none;
  margin-top: 1.8rem;
  margin-bottom: 4rem;
  padding-bottom: 10px;
}

.dropdown-menu {
  max-height: 300px !important;
  overflow: auto !important;
}
</style>
