<template>
  <div class="page-owners-associations-documents">
    <header-back v-if="is_capacitor" title=" VOLVER" :url="'/consorcios/'+owners_association.id" />
  <div class="container">
      <p class="section-header">Gestión de Comprobantes</p>
    </div>

    <vue-element-loading :active="isLoading" spinner="spinner" color="#CE4169" />

    <div >

      <div v-if="is_capacitor">
       <div  class="container mt-2" v-if="!isLoading">
        <h2 class="section-header max_header" @click="open" >
          <span class="font-weight-bold text-uppercase" style="color: #CE4169">{{period.title}}<span class="dropdown-toggle ml-2"></span></span>
        </h2>
      </div>
        <vue-bottom-sheet ref="myBottomSheet" >
          <div v-for="period in periods" :key="period.id" @click="changePeriod(period)">
            <hr>
            <span class="ml-3">{{period.title}}</span>
          </div>
          <br>
        </vue-bottom-sheet>
      </div>
<div v-else>

  <div class="container" v-if="periods == null">
    <h2 class="section-header max_header"><div class="btn-group"><button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn btn-light btn-lg dropdown-toggle" style="height: auto; padding: 9px 10px 7px;"> {{period.title}}</button>
    </div></h2></div>
  <dropdown-selector
      v-else
      @selected="changePeriod"
      :options="periods"
      :default="period"
  />
</div>
      <div v-if=" daysLeft().show ===true || !vouchers" class="empty-state empty-state-documents-date"  >
        <div class="wrapper">
          <h4>¡Aún no hay comprobantes!</h4>
          <p v-if="vouchers">Tus comprobantes estarán disponibles en {{daysLeft().days}} dias</p>
          <p v-else>En esta sección vas a encontrar comprobantes.</p>
          <img src="@/assets/img/empty-states/no-hay-documentos.png" class="image-empty" alt="">
        </div>
      </div>
      <template v-for="(voucher, index) in vouchers" v-else>
        <div class="documents-index" :key="index">
          <div class="container">
            <div class="let">
              <p>{{index}}</p>
            </div>
          </div>
          <div class="container">
            <div class="document_card" style="padding-bottom: 0">

              <router-link :to="{ name: 'detalle-comprobante', params: { owners_association_id: owners_association.id,id: item.id, voucher:item, owners_association: owners_association } }"    class="document-item" v-for="item in voucher" :key="item.id" >
              <span :class="item.invoice_url ? 'document-icon-container circled_active' : 'document-icon-container circled'">

                <img src="@/assets/img/icons/document.svg" class="document-icon" alt="" /></span>

                <div class="document-data" style="white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;">

                  <div class="document-title voucher">
                    <h5 class="document-title">{{item.supplier_name}}</h5>
                    <h4 class="document-category" >{{item.invoice_description}}</h4>
                  </div>

                </div>
                <div style="margin-left: 4.5rem;">
                  {{item.invoice_amount | currency}}
                </div>
              </router-link>

            </div>
          </div>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
  import { Capacitor } from '@capacitor/core';
  import VueBottomSheet from '@webzlodimir/vue-bottom-sheet';
  import axios from 'axios';
  import DropdownSelector from '@/components/Layouts/DropdownSelector';
  import HeaderBack from '@/components/Layouts/HeaderBack';
  import Settlements from '@/components/Settlements/Settlements';

  export default {
    name: 'Vouchers',
    components: {
      DropdownSelector, HeaderBack, VueBottomSheet, Settlements,
    },
    props: [],
    data() {
      return {
        browserUrl: window.location,
        vouchers: null,
        existing_vouchers: false,
        periods: null,
        period: null,
        isLoading: false,
        settlement_created_at: null,
        invoices_delay: 15,
      };
    },
    computed: {
      owners_association() {

        return this.$store.getters.ownersAssociation;
      },
      is_capacitor() {
        if (Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios') {
          return true;
        }
        return false;
      },

    },
    methods: {

      redirect(voucher) {
        console.log('owners_association:', this.owners_association);
        this.$router.push({
          name: 'detalle-comprobante',
          params: {
            owners_association: this.owners_association,
            voucher,
          },
        });
      },
      daysLeft() {
        // Obtener la fecha actual
        const fechaActual = new Date();

        console.log(this.settlement_created_at);
        console.log(this.invoices_delay);

        // eslint-disable-next-line no-restricted-globals
        if (!this.settlement_created_at || isNaN(this.invoices_delay)) return { show: false, days: 0 };
        // Convertir la fecha proporcionada a un objeto Date
        const fechaComparar = new Date(this.settlement_created_at);

        // Sumar la cantidad de días a la fecha dada

        console.log(fechaComparar);
        console.log(this.invoices_delay);
        // eslint-disable-next-line radix
        fechaComparar.setDate(fechaComparar.getDate() + parseInt(this.invoices_delay));
        console.log(fechaComparar);

        // Calcular la diferencia en días
        const diferenciaMs = Math.abs(fechaActual - fechaComparar);
        const diferenciaDias = Math.ceil(diferenciaMs / (1000 * 60 * 60 * 24));

        // Verificar si la fecha resultante es menor o igual a la fecha actual
        if (fechaComparar <= fechaActual) {
          return { show: false, days: diferenciaDias };
        }
        return { show: true, days: diferenciaDias };

      },
open() {
        console.log(this.periods);
        if (this.periods != null) this.$refs.myBottomSheet.open();
      },
      close() {
        this.$refs.myBottomSheet.close();
      },
      async changePeriod(period) {

        this.isLoadingSettlements = true;
        try {
          // Set period related info
          await this.getInteractiveInvoices(this.owners_association.id, period.id).then(async (res) => {
            this.vouchers = await res.interactive_invoices;
            this.period = await res.period;
            this.settlement_created_at = res.settlement_created_at;
            console.log(res);
            console.log(res.settlement_created_at);
            this.invoices_delay = this.owners_association.administration.invoices_delay;
            this.periods = await res.all_periods.reverse();
          });

         let url = this.browserUrl.href;

         if (url.includes('?')) {

         url = url.substr(0, url.indexOf('?'));
         }

          const stateObj = { id: '100' };
          window.history.pushState(stateObj,
              `${window.title}`, `${url}?period_id=${period.id}`);
          console.log(`current ${this.current_option}`);

          this.isLoadingSettlements = false;
          this.close();
        } catch (err) {
          this.isLoadingSettlements = false;
          this.close();
        }
      },
    },
    async created() {
      this.isLoading = true;

      if (this.$route.query.period_id) {

        await this.getInteractiveInvoices(this.owners_association.id, this.$route.query.period_id).then(async (res) => {
          this.vouchers = await res.interactive_invoices;
         this.settlement_created_at = res.settlement_created_at;
         console.log(res);
         console.log(res.settlement_created_at);
          this.invoices_delay = this.owners_association.administration.invoices_delay;
          this.period = await res.period;

          this.periods = await res.all_periods.reverse();
        
          this.isLoading = false;
        });

      } else {

        this.getConfirmVouchers(this.owners_association.id).then(async (voucher) => {

          this.vouchers = await voucher.interactive_invoices;

          this.periods = await voucher.all_periods;

          this.period = await voucher.period;

          this.isLoading = false;
        }).catch(() => {
          this.isLoading = false;
        });

      }
      console.log('owners_association:', this.owners_association);
    },
  };
</script>

<style lang='scss'>
  .document-item{
    margin-bottom: 0;
  }
  .documents-index .document-item .document-data{
    margin-top: 0 !important;
  }
  .documents-index .document-item .document-data .document-title{
    margin-top: 0 !important;
    font-weight: bold;
  }
  .let p {
    text-transform: lowercase;
    font-weight: bold;
    color: #858991;
  }

  .let p:first-letter {
    text-transform: uppercase;
  }
  .page-owners-associations-documents{
    padding-bottom: 5rem;
  }
  @media (max-width: 770px) {
    .let p {
      margin-bottom: 2rem;
    }
  }
</style>
