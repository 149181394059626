<template>
  <div class="amenities-page-wrapper">
    <div class="container">
      <p class="section-header">
        <router-link
            :to="{ name: 'listado-amenities'}"
            class="card-link btn btn-rounded btn-outline-primary value"
            style="display: inline-block;">
          Amenities
        </router-link>
        <router-link
            :to="{ name: 'listado-reservas'}"
            class="card-link btn btn-rounded btn-primary value"
            style="display: inline-block;">
          Reservas
        </router-link>
      </p>
    </div>
    <div class="page-content container-fluid">
      <vue-element-loading :active="isLoading" background-color="rgba(255, 255, 255, .0)" color="#CE4169"
                           spinner="spinner"/>
      <div class="empty-state empty-state-amenities" v-if="!isLoading && !amenitiesBookings.length">
        <div class="wrapper">
          <h4>¡Aún no hay reservas!</h4>
          <p>No se han encontrado reservas disponibles.</p>
          <img alt="" class="image-empty" src="@/assets/img/empty-states/no-hay-amenities.png">
        </div>
      </div>
      <div v-if="!is_capacitor" class="card-deck" style="align-content: space-evenly; justify-content: center; margin-top: 1em">
        <div class="amenity" v-for="booking in amenitiesBookings" :key="booking.id">
          <div class="card">
              <img :src="booking.image" class="card-img-top">
            <span :style="{ backgroundColor: booking.color}" style="color: #f2f2f2; text-align: center; width:100%">
                              {{booking.booking_status}}
                            </span>
            <div class="card-body">

              <div class="card-title">
                <div class="amenity-title-wrapper">
                  <h5 class="amenity-title" style="text-align: center;margin-bottom: 1.5rem">{{booking.title}}</h5>
                  <h6 class="amenity-title" style="text-align: center;margin-bottom: 1.5rem">{{booking.functional_unit.location}}</h6>
                </div>
              </div>
              <div class="card-text">
                <h5 style="font-size: 14px; text-align: center; margin-bottom: 0"> Fecha de
                  reserva: </h5>
                <h6 class="amenity-title" style="font-size: 16px; text-align: center">
                  {{convertirFecha(booking.date)}}</h6>

                <h5 style="font-size: 14px; text-align: center; margin-bottom: 0;">Horario:</h5>
                <h6 class="amenity-title" style="font-size: 14px; text-align: center; margin-bottom: 2.5rem">
                  {{booking.slot_name}}</h6>

              </div>
              <div class="data_actions" style="text-align: center">
                <div class="actions" style="text-align: center">
                  <router-link
                      :to="{ name: 'detalle-reserva', params: { booking: booking } }"
                      class="card-link btn btn-rounded btn-outline-primary"
                      >
                    Ver Detalles
                  </router-link>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="card shadow" :style="'border-left: 8px solid'+ booking.color" v-for="booking in amenitiesBookings" :key="booking.id" @click="redirectToDetail(booking)">
        <div class="card-body" >
          <h4 class="card-title">{{convertirFecha(booking.date)}}</h4>
           <h5 class="card-subtitle mb-2 font-weight-normal">{{booking.functional_unit.location}}</h5>
            <br>
          <h5 class="card-subtitle mb-2 font-weight-normal">Horario: {{booking.slot_name}}</h5>
          <div class="card-text text-muted">
            <span>Reserva: {{booking.booking_status}}</span>
            <br>
            <span>Espacio: {{booking.type}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';

export default {
  name: 'BookingsList',
  props: ['getTypeImage', 'amenitiesBookings'],
  data() {
    return {};
  },
  computed: {
    isLoading() {
      return !this.amenitiesBookings;
    },
    is_capacitor() {
      return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
    },
  },
  methods: {
    convertirFecha(fecha) {
      return this.convertLargeFecha(fecha);
    },
    redirectToDetail(booking) {
      this.$router.push({ name: 'detalle-reserva', params: { booking } });
    },
  },
  filters:
      {
        excerpt(text) {
          return text.length < 250 ? text : `${text.substr(0, 250)}...`;
        },
      },
};
</script>

<style lang='scss'>

</style>
