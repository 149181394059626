<template>
  <div class="page-account-view amenity-page-wrapper">
    <!-- :url="'/consorcios/' + owners_association.id + '/bookings'" -->
    <header-back ></header-back>
    <section class="bar theme-blue">

      <div v-if="booking.name" class="container">

        <div class="bar-img">
          <img :src="getTypeImage(booking)"/>
        </div>
        <h3 class="bar-firm-name">
          {{ booking.name }}
        </h3>

        <h4 class="amenity-title" style="text-align: center;margin-bottom: 1.5rem">{{booking.functional_unit.location}}</h4>
        <h5 class="bar-tagline">
          {{ booking.type }} &mdash;
        </h5>
      </div>
    </section>

    <div class="container amenity bar-details">
      <div class="card border-box">
        <!--- TODO: Cambiar :src a imagen amplificada del booking ----->

        <img :src="booking.image" class="card-img-top">
        <span :style="{ backgroundColor: booking.color}" style="color: #f2f2f2; text-align: center; width:100%">
                              {{ booking.booking_status }}
                            </span>
        <div class="card-body">
          <div class="card-title">
            <div class="amenity-title-wrapper">
              <h5 class="amenity-title" style="text-align: center;margin-bottom: 1.5rem">{{
                  booking.title
                }}</h5>
              <h4 class="amenity-title" style="text-align: center;margin-bottom: 1.5rem">{{booking.functional_unit.location}}</h4>
            </div>
          </div>
          <div class="card-text">
            <h5 style="font-size: 14px; text-align: center; margin-bottom: 0"> Fecha de reserva: </h5>
            <h6 class="amenity-title" style="font-size: 16px; text-align: center">
              {{ convertirFecha(booking.date) }}</h6>
            <br>
            <h5 style="font-size: 14px; text-align: center; margin-bottom: 0;">Horario:</h5>
            <h6 class="amenity-title" style="font-size: 14px; text-align: center; margin-bottom: 2.5rem">
              {{ booking.slot_name }}</h6>
            <p v-if="booking.is_cancelled">
            <h5 v-if="booking.is_cancelled" style="font-size: 14px; text-align: center; margin-bottom: 0;">Motivo:</h5>
            <h6 v-if="booking.is_cancelled" class="amenity-title"
                style="font-size: 14px; text-align: center; margin-bottom: 2.5rem">
              {{ booking.cancel_reason }}</h6>

            <h6 class="amenity-title" style="font-size: 14px; text-align: center; margin-bottom: 2.5rem" v-if="booking.book_notes">
              Comentarios</h6>
            <p v-if="booking.book_notes">
              {{booking.book_notes}}
            </p>
          </div>
          <div v-if="!booking.is_cancelled" class="data_actions" style="text-align: center">
            <div v-if="!booking.is_cancelled" class="actions" style="text-align: center">
              <button v-if="!booking.is_cancelled" class="btn btn-rounded btn-primary btn-block" @click="doCancelBooking()">
                Cancelar reserva
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBack from '@/components/Layouts/HeaderBack';

export default {
  name: 'BookingDetail',
  components: { HeaderBack },
  props: ['booking', 'owners_association'],
  data() {
    return {};
  },
  computed: {},
  methods: {
    doCancelBooking() {
      this.$emit('doCancelBooking');
    },
    convertirFecha(fecha) {
      return this.convertLargeFecha(fecha);
    },
  },
};
</script>

<style lang='scss'>

</style>
